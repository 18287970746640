import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';


import { LocalStorageService } from '../../../../shared/storage/local-storage.service';
import { DashboardService } from '../../../../dashboard/shared/dashboard.service';

import { createEmbeddingContext, DashboardContentOptions, FrameOptions, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { AuthService } from '../../../../auth/shared/auth.service';
import { LogService } from '../../../../shared/log/log.service';
import { UtilService } from '../../../../shared/util/util.service';
import { McTradeService } from '../../shared/mctrade.service';
import { RevenueItemTimeSeriesPredictionDto } from '../../shared/dto/revenueitem-timeseries-prediction-dto';
import { SelectableItemDto } from 'src/app/shared/dto/selectable-item-dto';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ApiService } from '../../../../shared/api/api.service';
import { InisghtsService } from 'src/app/insights/shared/insights.service';
import { CsvService } from 'src/app/shared/csv/csv.service';
import { Table } from 'primeng/table';
import { RevenueItemDataDto } from '../../shared/dto/revenue-item-data-dto';

@Component({
  selector: 'mctrade-revenue-predictive-ml',
  templateUrl: './mctrade-revenue-predictive-ml.component.html',
  styleUrls: ['./mctrade-revenue-predictive-ml.component.css']
})
export class MctradeRevenuePredictiveMlComponent implements OnInit {

  public keyword: string = "";
  public profileIdsCsv: string;
  public membersOnly: boolean = true;

  public revenuePredictionsDues: RevenueItemTimeSeriesPredictionDto = new RevenueItemTimeSeriesPredictionDto();
  public revenuePredictionsNonDues: RevenueItemTimeSeriesPredictionDto = new RevenueItemTimeSeriesPredictionDto();
  public revenuePredictionsEvents: RevenueItemTimeSeriesPredictionDto = new RevenueItemTimeSeriesPredictionDto();

  public revenuePredictionsSummary: RevenueItemTimeSeriesPredictionDto = new RevenueItemTimeSeriesPredictionDto();

  public revenueActualsSummary: Array<RevenueItemDataDto> = new Array<RevenueItemDataDto>();
  public revenueActualsDues: Array<RevenueItemDataDto> = new Array<RevenueItemDataDto>();
  public revenueActualsNonDues: Array<RevenueItemDataDto> = new Array<RevenueItemDataDto>();
  public revenueActualsEvents: Array<RevenueItemDataDto> = new Array<RevenueItemDataDto>();



  public revenueGridData = [];

  public revenueSummaryChartData = {};
  public revenueDuesChartData = {};
  public revenueNonDuesChartData = {};
  public revenueEventsChartData = {};

  public revenueSummaryChartDataActual = {};
  public revenueDuesChartDataActual = {};
  public revenueNonDuesChartDataActual = {};
  public revenueEventsChartDataActual = {};

  private _tenantId: string = "";
  private _productId: string = "";

  public monthNames = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];


  @ViewChild('scrollTarget')
  scrollTargetRef: ElementRef;

  constructor(private _dashboardService: DashboardService, private _insightsService: InisghtsService, private _logService: LogService, private _authService: AuthService, private _utilService: UtilService, private _notifyService: NotificationService, private _mcTradeDataService: McTradeService, private _apiService: ApiService, private _csvService: CsvService) {


  }
  ngOnInit(): void {


    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

    var curDateMonth = new Date();

    var year = curDateMonth.getFullYear();
    var month = curDateMonth.getMonth() + 1; //month is 0 based the api expects 1 based
    //we want to pass the previous full month to the api
    // if (month == 1) {
    //   year = year - 1;
    //   month = 12;
    // } else {
    //   month = month - 1;
    // }

    this.revenueGridData = [];

    var promise1 = this._mcTradeDataService.GetSavedRevenuePredictions(year, month, "dues").then((data: RevenueItemTimeSeriesPredictionDto) => {

      this.revenuePredictionsDues = data;



      //this.revenuePredictionsDues = data.filter(item => item.revenueType == "dues")[0];
      this.revenueGridData.push(data);




    });

    var promise2 = this._mcTradeDataService.GetSavedRevenuePredictions(year, month, "events").then((data : RevenueItemTimeSeriesPredictionDto) => {

      this.revenuePredictionsEvents = data;
      //this.revenuePredictionsEvents = data.filter(item => item.revenueType == "events")[0];

      this.revenueGridData.push(data);




    });

    var promise3 = this._mcTradeDataService.GetSavedRevenuePredictions(year, month, "non-dues").then((data: RevenueItemTimeSeriesPredictionDto) => {


      this.revenuePredictionsNonDues = data;
      //this.revenuePredictionsNonDues = data.filter(item => item.revenueType == "non-dues")[0];

      this.revenueGridData.push(data); 


    });

    var promise4 = this._mcTradeDataService.GetSavedRevenueActuals(year, month, "dues").then((data: Array<RevenueItemDataDto>) => {

      this.revenueActualsDues = data.sort((a, b) => {
        if (a.year != b.year) {
          return a.year - b.year;
        } else {
          return a.month - b.month;
        }
      }).slice(0).slice(-36);

    });

    var promise5 = this._mcTradeDataService.GetSavedRevenueActuals(year, month, "events").then((data: Array<RevenueItemDataDto>) => {

      this.revenueActualsEvents = data.sort((a, b) => {
        if (a.year != b.year) {
          return a.year - b.year;
        } else {
          return a.month - b.month;
        }
      }).slice(0).slice(-36)

    });

    var promise6 = this._mcTradeDataService.GetSavedRevenueActuals(year, month, "non-dues").then((data: Array<RevenueItemDataDto>) => {

      this.revenueActualsNonDues = data.sort((a, b) => {
        if (a.year != b.year) {
          return a.year - b.year;
        } else {
          return a.month - b.month;
        }
      }).slice(0).slice(-36)
    });



    Promise.all([promise1, promise2, promise3, promise4, promise5, promise6]).then(() => {

      this.revenuePredictionsSummary.month = this.revenuePredictionsDues.month;
      this.revenuePredictionsSummary.year = this.revenuePredictionsDues.year;

      let i: number = 0;
      while (i < 12) {

        //this.revenuePredictionsSummary.confidenceLowerBound[i] = this.revenuePredictionsDues.confidenceLowerBound[i] + this.revenuePredictionsNonDues.confidenceLowerBound[i] + this.revenuePredictionsEvents.confidenceLowerBound[i];
        this.revenuePredictionsSummary.forecastedRevenue[i] = this.revenuePredictionsDues.forecastedRevenue[i] + this.revenuePredictionsNonDues.forecastedRevenue[i] + this.revenuePredictionsEvents.forecastedRevenue[i];
        //this.revenuePredictionsSummary.confidenceUpperBound[i] = this.revenuePredictionsDues.confidenceUpperBound[i] + this.revenuePredictionsNonDues.confidenceUpperBound[i] + this.revenuePredictionsEvents.confidenceUpperBound[i];
        i++;
      }

      this.getCharts();
    });

  }

  getCharts() {

    var chartLabels = [];
    var chartLabelsActual = [];
    

    var index = 0;
    for (let item of this.revenuePredictionsDues.forecastedRevenue) {

      chartLabels.push(this.getPeriod(index));
      index = index + 1;

    }

    for (let item of this.revenueActualsDues) {

      chartLabelsActual.push(this.getPeriodActuals(item.year, item.month))

    }

    this.revenueSummaryChartData = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Predicted Revenue',
          data: this.revenuePredictionsSummary.forecastedRevenue,
          fill: false,
          borderColor: '#1A613C',
          tension: .4
        },
        // {
        //   label: 'Lower Bound',
        //   data: this.revenuePredictionsSummary.confidenceLowerBound,
        //   fill: false,
        //   borderColor: '#FFA726',
        //   tension: .4
        // },
        // {
        //   label: 'Upper Bound',
        //   data: this.revenuePredictionsSummary.confidenceUpperBound,
        //   fill: false,
        //   borderColor: '#FFA726',
        //   tension: .4
        // }
      ]
    };

    this.revenueDuesChartData = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Predicted Revenue',
          data: this.revenuePredictionsDues.forecastedRevenue,
          fill: false,
          borderColor: '#1A613C',
          tension: .4
        },
        // {
        //   label: 'Lower Bound',
        //   data: this.revenuePredictionsDues.confidenceLowerBound,
        //   fill: false,
        //   borderColor: '#FFA726',
        //   tension: .4
        // },
        // {
        //   label: 'Upper Bound',
        //   data: this.revenuePredictionsDues.confidenceUpperBound,
        //   fill: false,
        //   borderColor: '#FFA726',
        //   tension: .4
        // }
      ]
    };


    this.revenueNonDuesChartData = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Predicted Revenue',
          data: this.revenuePredictionsNonDues.forecastedRevenue,
          fill: false,
          borderColor: '#1A613C',
          tension: .4
        },
        // {
        //   label: 'Lower Bound',
        //   data: this.revenuePredictionsNonDues.confidenceLowerBound,
        //   fill: false,
        //   borderColor: '#FFA726',
        //   tension: .4
        // },
        // {
        //   label: 'Upper Bound',
        //   data: this.revenuePredictionsNonDues.confidenceUpperBound,
        //   fill: false,
        //   borderColor: '#FFA726',
        //   tension: .4
        // }
      ]
    };

    this.revenueEventsChartData = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Predicted Revenue',
          data: this.revenuePredictionsEvents.forecastedRevenue,
          fill: false,
          borderColor: '#1A613C',
          tension: .4
        },
        // {
        //   label: 'Lower Bound',
        //   data: this.revenuePredictionsEvents.confidenceLowerBound,
        //   fill: false,
        //   borderColor: '#FFA726',
        //   tension: .4
        // },
        // {
        //   label: 'Upper Bound',
        //   data: this.revenuePredictionsEvents.confidenceUpperBound,
        //   fill: false,
        //   borderColor: '#FFA726',
        //   tension: .4
        // }
      ]
    }

    this.revenueDuesChartDataActual = {
      labels: chartLabelsActual,
      datasets: [
        {
          label: 'Actual Revenue',
          data: this.revenueActualsDues.map((x) => { return x.amount_sum }),
          fill: false,
          borderColor: '#1A613C',
          tension: .4
        },

      ]
    };

    this.revenueEventsChartDataActual = {
      labels: chartLabelsActual,
      datasets: [
        {
          label: 'Actual Revenue',
          data: this.revenueActualsEvents.map((x) => { return x.amount_sum }),
          fill: false,
          borderColor: '#1A613C',
          tension: .4
        },

      ]
    };

    this.revenueNonDuesChartDataActual = {
      labels: chartLabelsActual,
      datasets: [
        {
          label: 'Actual Revenue',
          data: this.revenueActualsNonDues.map((x) => { return x.amount_sum }),
          fill: false,
          borderColor: '#1A613C',
          tension: .4
        },

      ]
    };
  }

  getPeriod(i: number) {

    var month = this.revenuePredictionsDues.month + i;
    var year = this.revenuePredictionsDues.year;
    if (month > 12) {
      month = month - 12;
      year = year + 1;
    }

    //javascript month is 0 based
    var displayName = "";
    if (month >= 1 && month <= 12) {
      month = month - 1;
      displayName = this.monthNames[month].toString() + " " + year.toString();
    }

    return displayName;
  }

  getPeriodActuals(year: number, month: number) {

     var displayName = this.monthNames[month - 1].toString() + " " + year.toString();;
    return displayName;
  }



  getRevenueSum(i: number) {

    var sum = 0;
    // if (this.revenueGridData.length > 0) {
    //   sum = this.revenueGridData[0].forecastedRevenue[i] + this.revenueGridData[1].forecastedRevenue[i] + this.revenueGridData[2].forecastedRevenue[i];
    // }

    return sum;
  }

  exportToCsv() {

    var rows = [];
    var headerRow = [];

    headerRow.push("revenuetype");
    headerRow.push(this.getPeriod(0));
    headerRow.push(this.getPeriod(1));
    headerRow.push(this.getPeriod(2));
    headerRow.push(this.getPeriod(3));
    headerRow.push(this.getPeriod(4));
    headerRow.push(this.getPeriod(5));
    headerRow.push(this.getPeriod(6));
    headerRow.push(this.getPeriod(7));
    headerRow.push(this.getPeriod(8));
    headerRow.push(this.getPeriod(9));
    headerRow.push(this.getPeriod(10));
    headerRow.push(this.getPeriod(11));

    rows.push(headerRow);

    for (var item of this.revenueGridData) {

      var dataRow = [];
      dataRow.push(item.revenueType);
      dataRow.push(item.forecastedRevenue[0]);
      dataRow.push(item.forecastedRevenue[1]);
      dataRow.push(item.forecastedRevenue[2]);
      dataRow.push(item.forecastedRevenue[3]);
      dataRow.push(item.forecastedRevenue[4]);
      dataRow.push(item.forecastedRevenue[5]);
      dataRow.push(item.forecastedRevenue[6]);
      dataRow.push(item.forecastedRevenue[7]);
      dataRow.push(item.forecastedRevenue[8]);
      dataRow.push(item.forecastedRevenue[9]);
      dataRow.push(item.forecastedRevenue[10]);
      dataRow.push(item.forecastedRevenue[11]);

      rows.push(dataRow);

    }
    this._csvService.exportToCsv("revenue_prediciton.csv", rows, true);
  }
}
