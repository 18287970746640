import { IdentityProviderService } from '../shared/identity-provider.service';
import { environment } from '../../../environments/environment';
import { Component, OnInit } from '@angular/core';
 

@Component({
  selector: 'psfy-login',
  templateUrl: './login-psfy.component.html',
  styleUrls: ['./login-psfy.component.css'],
})
export class LoginPsfyComponent implements OnInit {
  public isLoggedIn = false;
  public displayResponse: string;
  public tenant: string = "";

  constructor(private _identityProviderService: IdentityProviderService) {


  }

  ngOnInit(): void {


    

  }

  login() : void{

    var returnHint = 'home';
    this._identityProviderService.usePsfyIdp(true, this.tenant);
    this._identityProviderService.initialize();
    this._identityProviderService.authorizeCodeFlowRequestCode(returnHint);

  }
}
