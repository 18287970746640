<div class="grid" style="width:100%">

  <!-- <div class="col-12" i18n>
    Forcasted Revenue By Type
  </div> -->
  <div class="col-12">
    <p-table [value]="revenueGridData" styleClass="p-datatable-striped p-datatable-gridlines" responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <span><p-button styleClass="p-button-link" (click)="exportToCsv()" i18n-label
                label="Download Data"></p-button></span>
                
    </ng-template>
      <ng-template pTemplate="header">

        
        <tr>
          <th></th>
          <th>{{getPeriod(0)}}</th>
          <th>{{getPeriod(1)}}</th>
          <th>{{getPeriod(2)}}</th>
          <th>{{getPeriod(3)}}</th>
          <th>{{getPeriod(4)}}</th>
          <th>{{getPeriod(5)}}</th>
          <th>{{getPeriod(6)}}</th>
          <th>{{getPeriod(7)}}</th>
          <th>{{getPeriod(8)}}</th>
          <th>{{getPeriod(9)}}</th>
          <th>{{getPeriod(10)}}</th>
          <th>{{getPeriod(11)}}</th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td>{{item.revenueType|uppercase}}</td>
          <td><span style="float: right">{{item.forecastedRevenue[0]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[1]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[2]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[3]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[4]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[5]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[6]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[7]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[8]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[9]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[10]|currency}}</span></td>
          <td><span style="float: right">{{item.forecastedRevenue[11]|currency}}</span></td>

        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td></td>
          <td><span style="float: right">{{getRevenueSum(0)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(1)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(2)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(3)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(4)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(5)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(6)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(7)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(8)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(9)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(10)|currency}}</span></td>
          <td><span style="float: right">{{getRevenueSum(11)|currency}}</span></td>

        </tr>
      </ng-template>

    </p-table>

  </div>

  <!-- <div class="col-6" i18n>
   All Revenue
  </div>
  <div class="col-6" i18n>
    Projected
   </div>  

  <div class="col-6">
    <p-chart type="line" [data]="revenueSummaryChartData"></p-chart>
  </div>  
  <div class="col-6">
    
  </div> -->

  <div class="col-6" i18n>
    Historical Dues
   </div>
   <div class="col-6" i18n>
     Projected Dues
    </div>  
 
   <div class="col-6">
    <p-chart type="line" [data]="revenueDuesChartDataActual"></p-chart>
   </div>  
   <div class="col-6">    
    <p-chart type="line" [data]="revenueDuesChartData"></p-chart>
   </div>

   <div class="col-6" i18n>
    Historical Events
   </div>
   <div class="col-6" i18n>
     Projected Events
    </div>  
 
   <div class="col-6">
    <p-chart type="line" [data]="revenueEventsChartDataActual"></p-chart>
   </div>  
   <div class="col-6">    
    <p-chart type="line" [data]="revenueEventsChartData"></p-chart>
   </div>


   <div class="col-6" i18n>
    Historical Non Dues
   </div>
   <div class="col-6" i18n>
     Projected Non Dues
    </div>  
 
   <div class="col-6">
    <p-chart type="line" [data]="revenueNonDuesChartDataActual"></p-chart>
   </div>  
   <div class="col-6">    
    <p-chart type="line" [data]="revenueNonDuesChartData"></p-chart>
   </div>


  <!-- <div class="col-6" i18n>
   Non Dues
  </div>
  <div class="col-6" i18n>
    Events
   </div> 

  <div class="col-6" >
    <p-chart type="line" [data]="revenueNonDuesChartData"></p-chart>
  </div>
   
  <div class="col-6">
    <p-chart type="line" [data]="revenueEventsChartData"></p-chart>
  </div> -->
 





  <!-- <div class="col-3" i18n>
    Period
  </div>
  <div class="col-3" i18n>
    Dues
  </div>
  <div class="col-3" i18n>
    Non-Dues
  </div>
  <div class="col-3" i18n>
    Events
  </div>
  <div class="col-3">

    <p-table [value]="revenuePredictionsDues.forecastedRevenue" styleClass="p-datatable-striped p-datatable-gridlines"
      responsiveLayout="scroll">

      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td>{{getPeriod(i)}}</td>

        </tr>
      </ng-template>

    </p-table>

  </div>

  <div class="col-3">

    <p-table [value]="revenuePredictionsDues.forecastedRevenue" styleClass="p-datatable-striped p-datatable-gridlines"
      responsiveLayout="scroll">

      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item| currency}}</td>

        </tr>
      </ng-template>

    </p-table>

  </div>

  <div class="col-3">

    <p-table [value]="revenuePredictionsNonDues.forecastedRevenue"
      styleClass="p-datatable-striped p-datatable-gridlines" responsiveLayout="scroll">

      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item| currency}}</td>

        </tr>
      </ng-template>

    </p-table>

  </div>

  <div class="col-3">

    <p-table [value]="revenuePredictionsEvents.forecastedRevenue" styleClass="p-datatable-striped p-datatable-gridlines"
      responsiveLayout="scroll">

      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item| currency}}</td>

        </tr>
      </ng-template>

    </p-table>

  </div>
-->
</div>