import { Injectable, QueryList } from '@angular/core';
import { Router, Params, NavigationStart, NavigationEnd, NavigationExtras, NavigationBehaviorOptions } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../util/util.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { AuthService } from 'src/app/auth/shared/auth.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public _utilService: UtilService;
  public _router: Router;
  public _localStorageService: LocalStorageService;

  public legacyDomain = '';
  private _productId: string = "";
  private _tenantId: string = "";

  public constructor(
    utilService: UtilService,
    router: Router,
    localStorageService: LocalStorageService,
    private _authService: AuthService
  ) {
    this._utilService = utilService;
    this._router = router;


    this._tenantId = this._authService.getUserSession().tenantId;
    this._productId = this._authService.getUserSession().productId;

  }

  public getLegacyDomain = (): string => {
    return this.legacyDomain;
  };



  public navigate = (url: string, queryParams?: Params): Promise<boolean> => {
    if (queryParams) {
      return this._router.navigate([url], { queryParams: queryParams });
    } else {
      return this._router.navigate([url]);
    }
  };

  public navigateByUrl = (url: string): Promise<boolean> => {
    return this._router.navigateByUrl(url);
  };

  public navigateAndReload = (url: string): Promise<boolean> => {

    return this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      return this._router.navigate([`/${url}`]);
    })
  }

  public getCurrentUrl = (): string => {
    return this._router.url;
  };


  public popupSaefeNavigate = (url: string): void => {
    this.popupSafeNavigate(url);
  };

  public popupSafeNavigate = (url: string): void => {
    let newWindow = window.open(url);
    setTimeout(() => {
      if (!newWindow || newWindow.innerHeight <= 0) {
        alert('popup blocked');
      }
    }, 200);
  };



  public setCurrentQueryParams(queryParams: object, currentRoute: ActivatedRoute): Promise<boolean> {
    return this._router.navigate([], {
      relativeTo: currentRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }


  //specififc navigation routes
  public navigateToAppRootAndReload = (): void => {

    var urlParts = this._utilService.getUrlParts(window.location.href);
    var redirectUrl = "https://" + urlParts.host; //+ urlParts.pathname;
    window.location.href = redirectUrl;

    // var urlParts = this._utilService.getUrlParts(window.location.href);
    // var url = "https://" +   window.location.host; //urlParts.host; //+ urlParts.pathname;
    // this.navigateAndReload("");


  };

  public navigateToHome = (): Promise<boolean> => {
    return this._router.navigateByUrl('/');
  };

  public navigateToLogin = (): Promise<boolean> => {
    return this._router.navigateByUrl('/login');
  };

  public navigateToPsfyLogin= (): Promise<boolean> => {
    return this._router.navigateByUrl('/login-psfy');
  };

  public navigateToLogout = () => {
    return this._router.navigateByUrl('/logout');
  }

  public navigateToAdmin = (): Promise<boolean> => {
    return this._router.navigateByUrl('/admin');
  };

  public navigateToSettings = (): Promise<boolean> => {
    if (this._productId == "mc_trade") {
      return this.navigateAndReload('/mctrade/settings');
    } else {
      return Promise.resolve(false);
    }
  };

  public navigateToUnauthorized = (): Promise<boolean> => {
    return this._router.navigateByUrl('/unauthorized');
  };

  public navigateToAiAssistant = (): Promise<boolean> => {

    return this.navigateAndReload('/ai-assistant');

  };

  public navigateToDashboard = (): Promise<boolean> => {
    if (this._productId == "mc_trade") {
      return this.navigateAndReload('/mctrade/dashboard');
    } else {
      return Promise.resolve(false);
    }
  };

  public navigateToPredictiveAnalysis = (): Promise<boolean> => {
    if (this._productId == "mc_trade") {
      return this.navigateAndReload('/mctrade/predictive-analysis');
    } else {
      return Promise.resolve(false);
    }
  }

  public navigateToAiDataAnalyst = (): Promise<boolean> => {

    if (this._productId == "mc_trade") {
      return this.navigateAndReload('/mctrade/ai-data-analyst');
    } else {
      return Promise.resolve(false);
    }


  }

  public navigateToMembershipDashboard = (): Promise<boolean> => {
    if (this._productId == "mc_trade") {
      return this.navigateAndReload('/mctrade/membership-dashboard');
    } else {
      return Promise.resolve(false);
    }
  };

  public navigateToEventsDashboard = (): Promise<boolean> => {
    if (this._productId == "mc_trade") {
      return this.navigateAndReload('/mctrade/event-dashboard');
    } else {
      return Promise.resolve(false);
    }
  };

  public navigateToRevenueDashboard = (): Promise<boolean> => {

    if (this._productId == "mc_trade") {
      return this.navigateAndReload('/mctrade/revenue-dashboard');
    } else {
      return Promise.resolve(false);
    }
  };

  public navigateToMyDashboard = (): Promise<boolean> => {

    if (this._productId == "mc_trade") {
      return this.navigateAndReload('/mctrade/my-dashboard');
    } else {
      return Promise.resolve(false);
    }
  };




  public navigateToInsightsBuilder = (): Promise<boolean> => {
    return this._router.navigateByUrl('/insights-builder');
  };

  public navigateToAdminLogin = (): Promise<boolean> => {
    return this._router.navigateByUrl('/login');
  };

public navigateToAdminTenant = (tenantId:string) :  Promise<boolean> => {
  return this._router.navigateByUrl('/admin/tenant/' + tenantId);
};




}
