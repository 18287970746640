<h3>Login</h3>


    

        <div class="grid">

             
      
            <div class="col-3">
              <label i18n for="tenant">Tenant Id</label>
            </div>
            <div class="col-6">
              <input id="tenant" class="w-full" type="text" pInputText
                [(ngModel)]="tenant">
            </div>
             
            <div class="col-3">
                <p-button id="btnLogin" i18n-label label="Login" (click)="login()" icon="pi pi-bolt"
            class="w-auto"></p-button>
            </div>
             
</div>      
