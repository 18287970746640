
import { TenantConfigDto } from "../../../../admin/shared/dto/tenant-config-dto";
import { ModelFieldOptionsDto } from "./model-field-options.dto";

export class MctradeTenantSettingsDto {

    public constructor(){
        this.id = "";
        this.tenantId = "";
        this.productId = "";
        this.retentionModelExcludeCategoricalData = false;
        this.segmentationModelExcludeCategoricalData = false;
        this.retentionModelFieldOptions = new Array<ModelFieldOptionsDto>();
        this.segmentationModelFieldOptions = new Array<ModelFieldOptionsDto>();
        this.profileAffCodesToIngest = new Array<string>();
        this.profileCustomFieldsToIngest = new Array<string>();
        this.config = new TenantConfigDto();
    }
    id: string;
    tenantId: string;
    productId: string;
    ingestionAsOfDate: Date;
    retentionModelFieldOptions:Array<ModelFieldOptionsDto>;
    segmentationModelFieldOptions:Array<ModelFieldOptionsDto>;
    retentionModelExcludeCategoricalData: boolean;
    segmentationModelExcludeCategoricalData: boolean;
    profileAffCodesToIngest: Array<string>;
    profileCustomFieldsToIngest: Array<string>;
    config: TenantConfigDto
}