<div class="grid">
    <div class="col-12">
        <label il18n>Ingest Data Going Back To: </label>
        <p-calendar [(ngModel)]="settings.ingestionAsOfDate" [minDate]="minDateValue" [maxDate]="maxDateValue"
            [readonlyInput]="true"></p-calendar>
        <label il18n> (Date must be >= 1/1/2019) </label>

    </div>

    <div class="col-6" *ngIf="selectedConfig.enableDynamicDatasetFields == true">
        <h3 il18n>Profile Aff Codes To Ingest</h3>
    </div>
    <div class="col-6" *ngIf="selectedConfig.enableDynamicDatasetFields == true">
        <h3 il18n>Profile Custom Fields To Ingest</h3>
    </div>

    <div class="col-6" *ngIf="selectedConfig.enableDynamicDatasetFields == true">
        <p-button id="addProfileAffCode" i18n-label label="Add Profile Aff Code" (click)="addProfileAffCode()"
            icon="pi pi-plus" class="w-auto"></p-button>

        <p-table [value]="profileAffCodesToIngestVM" styleClass="p-datatable-gridlines"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th i18n>Aff Code</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-affCode>
                <tr>

                    <td> <input class="w-full" type="text" pInputText [(ngModel)]="affCode.name" >
                    </td>

                    <td><p-button i18n-label label="Remove" (click)="removeProfileAffCode(affCode)" icon="pi pi-minus"
                            class="w-auto"></p-button></td>
                </tr>
            </ng-template>

        </p-table>
    </div>
    <div class="col-6" *ngIf="selectedConfig.enableDynamicDatasetFields == true">
        <p-button id="addProfileCustomField" i18n-label label="Add Profile Custom Field"
            (click)="addProfileCustomField()" icon="pi pi-plus" class="w-auto"></p-button>
        <p-table  [value]="profileCustomFieldsToIngestVM" editMode="row" styleClass="p-datatable-gridlines"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th i18n>Custom FIeld</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customField>
                <tr>

                    <td> <input class="w-full" type="text" pInputText [(ngModel)]="customField.name">
                    </td>

                    <td><p-button i18n-label label="Remove" (click)="removeProfileCustomField(customField)"
                            icon="pi pi-minus" class="w-auto"></p-button></td>
                </tr>
            </ng-template>

        </p-table>

    </div>


    <div class="col-6">
        <h3 il18n>Retention Model Field Options</h3>
    </div>
    <div class="col-6">
        <h3 il18n>Segmentation Model Field Options</h3>
    </div>



    <div class="col-6">
        <p-table [value]="retentionFieldNames" styleClass="p-datatable-striped p-datatable-gridlines">
            <ng-template pTemplate="caption">
                <p-checkbox [(ngModel)]="settings.retentionModelExcludeCategoricalData" [binary]="true"
                    (onChange)="retentionModelExcludeCategoricalDataCheckboxChanged()" il18n il18n-label
                    label="Retention Model Exclude All Categorical Data"></p-checkbox>

            </ng-template>
            <ng-template pTemplate="header">

                <tr>
                    <th i18n>Field Name (check fields to exclude from model)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vm>
                <tr>
                    <td><p-checkbox [disabled]="vm.hidden" [(ngModel)]="vm.selected" label="{{vm.item}}"
                            [binary]="true"></p-checkbox></td>
                </tr>
            </ng-template>

        </p-table>

    </div>
    <div class="col-6">
        <p-table [value]="segmentationFieldNames" styleClass="p-datatable-striped p-datatable-gridlines">
            <ng-template pTemplate="caption">
                <p-checkbox [(ngModel)]="settings.segmentationModelExcludeCategoricalData" [binary]="true"
                    (onChange)="segmentationModelExcludeCategoricalDataCheckboxChanged()" il18n il18n-label
                    label="Segmentation Model Exclude All Categorical Data"></p-checkbox>

            </ng-template>
            <ng-template pTemplate="header">

                <tr>
                    <th i18n>Field Name (check fields to exclude from model)</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vm>
                <tr>
                    <td><p-checkbox [disabled]="vm.hidden" [(ngModel)]="vm.selected" label="{{vm.item}}"
                            [binary]="true"></p-checkbox></td>
                </tr>
            </ng-template>

        </p-table>
    </div>

    <div class="col-12">
        <span>
            <p-button id="addProfileCustomField" i18n-label label="Save Settings" (click)="saveSettings()"
                icon="pi pi-check" class="w-auto"></p-button>
            <!-- <p-button styleClass="p-button-link" (click)="saveSettings()" i18n-label
            label="Save Settings"></p-button> -->
        </span>
    </div>

</div>