import { UtilService } from './../shared/util/util.service';
import { AdminService } from './shared/admin.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../shared/navigation/navigation.service';
import { RabTenantDto } from './shared/dto/rab-tenant-dto';
import { DataView } from 'primeng/dataview';
import { CreateTenantConfigDto } from './shared/dto/create-new-rab-tenant-dto';
import { SearchResultDto } from '../shared/dto/search-result-dto';
import { UserSession } from '../auth/shared/user-session.model';
import { AuthService } from '../auth/shared/auth.service';
import { NotificationService } from '../shared/notification/notification.service';
import { TabView } from 'primeng/tabview';
import { Dialog } from 'primeng/dialog';
import { TenantConfigDto } from './shared/dto/tenant-config-dto';


@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {

  public displayResponse: string;
  public tenants: Array<RabTenantDto> = new Array<RabTenantDto>();
  public selectedTenant: RabTenantDto = new RabTenantDto();
  public products: Array<string> = new Array<string>('', 'mc_trade');
  public selectedProduct: string = 'mc_trade'
  public isNewTenantRequest: boolean = false;
  public showDialog: boolean = false;
  public currentUserSession: UserSession = new UserSession()
  public selectedConfig: TenantConfigDto = new TenantConfigDto();

  constructor(
    private _adminService: AdminService,
    private _navigationService: NavigationService,
    private _utilService: UtilService,
    private _authService: AuthService,
    private _notificationService: NotificationService
  ) {

  }

  @ViewChild("tabView1") tabView1: TabView;
  @ViewChild("editDialog") editDialog: Dialog;

  ngOnInit() {


    this.getTenants();



  }

  getTenants() {
    this._adminService.getTenants().then((data: SearchResultDto<RabTenantDto>) => {
      this.tenants = data.result.sort((a, b) => a.tenantId.localeCompare(b.tenantId));

      var foo = '';
      if (this.tenants.length > 0) {
        this.selectedTenant = this.tenants[0];
      }
    });
  }


  selectTenant(tenant: RabTenantDto) {

    this._adminService.selectTenant(tenant);

    this.isNewTenantRequest == false;
    this.selectedTenant = tenant;
    this._adminService.getTenantConfig(tenant.productId, tenant.tenantId).then((data: TenantConfigDto) => {
      this._adminService.selectTenantConfig(data);
      this.selectedConfig = data;
      this._navigationService.navigateToAdminTenant(tenant.tenantId);
    });


  }
  cancel() {
    this.isNewTenantRequest == false;
    this.showDialog = false;
  }

  deleteTenant(tenant: RabTenantDto) {

    if (confirm("Are you sure you want to delete tenant " + tenant.tenantId + "?")) {
      // User clicked OK
      // Perform the action here
      this._adminService.deleteTenant(tenant).then(() => {
        this.getTenants();

      });
    } else {
      // User clicked Cancel
      // Do nothing or perform an alternate action
    }

  }

  newTenant() {
    this.isNewTenantRequest = true;
    this.selectedTenant = new RabTenantDto();
    this.selectedTenant.displayName = "New Tenant Name";
    this.selectedTenant.tenantId = "";
    this.selectedTenant.productId = "mc_trade";
    this.selectedTenant.adminEmail = "";
    this.selectedTenant.adminFirstName = "";
    this.selectedTenant.adminLastName = "";
    this.selectedTenant.adminPhone = "";
    this.selectedTenant.notifyEmail = "";

    this.showDialog = true;
  }

  deployNewTenant() {

    this.selectedTenant.changedBy = this._authService.getUserSession().userName;

    let newTenantConfig = new CreateTenantConfigDto();
    newTenantConfig.displayName = this.selectedTenant.displayName;
    newTenantConfig.tenantId = this.selectedTenant.tenantId;
    newTenantConfig.productId = "mc_trade";
    newTenantConfig.adminEmail = this.selectedTenant.adminEmail;
    newTenantConfig.adminFirstName = this.selectedTenant.adminFirstName;
    newTenantConfig.adminLastName = this.selectedTenant.adminLastName;
    newTenantConfig.adminPhone = this.selectedTenant.adminPhone;
    newTenantConfig.notifyEmail = this.selectedTenant.notifyEmail;
    newTenantConfig.changedBy = this.selectedTenant.changedBy;
    newTenantConfig.ingestDataFirst = true;
    newTenantConfig.injestionDataOverwriteExisting = true;
    newTenantConfig.injestionDataStartDate = new Date();
    newTenantConfig.injestionDataEndDate = this._utilService.addDays(new Date(), -20);


    this._adminService.createNewMcTradeTenant(newTenantConfig);
    this._notificationService.info("Creating new tenant " + this.selectedTenant.tenantId + ".  This may take several minutes.")

    this.isNewTenantRequest = false;



    this.getTenants();
    this.showDialog = false;

  }

  actAsTenant(tenant: RabTenantDto) {

    this.currentUserSession = this._authService.getUserSession();
    this.currentUserSession.tenantId = tenant.tenantId;
    this.currentUserSession.displayName = tenant.displayName ??= tenant.tenantId;
    this.currentUserSession.productId = tenant.productId;
    this.currentUserSession.powerUser = true;
    this.currentUserSession.adminUser = true;
    this._authService.setAdminUserSession(this.currentUserSession);

    this._adminService.getTenantSessionId(tenant.productId, tenant.tenantId).then((data: string) => {

      var url = "/" + this.currentUserSession.productId + "/" + this.currentUserSession.tenantId + "?sessionid=" + data;
      window.location.href = url;

    });




  }

  generateMlModels(tenant: RabTenantDto) {

    this._adminService.GenerateMCTradeMLModels(tenant.tenantId);
    this._notificationService.info("Generating ML Models...");
    this.tabView1.activeIndex = 0;
    this.showDialog = false;
  }



}
