import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { PostLoginComponent } from './auth/post-login/post-login.component';
import { AdminComponent } from './admin/admin.component';
import { UnauthorizedComponent } from './auth/unauthorized.component';
import { LogoutComponent } from './auth/logout/logout.component';

import { HomeComponent } from './home/home.component';
import { MctradeMembershipDashboardComponent } from './products/mctrade/dashboard/mctrade-membership-dashboard/mctrade-membership-dashboard.component';
import { MctradeEventDashboardComponent } from './products/mctrade/dashboard/mctrade-event-dashboard/mctrade-event-dashboard.component';
import { MctradeRevenueDashboardComponent } from './products/mctrade/dashboard/mctrade-revenue-dashboard/mctrade-revenue-dashboard.component';
import { MctradeMyDashboardComponent } from './products/mctrade/dashboard/mctrade-my-dashboard/mctrade-my-dashboard.component';
import { QSearchComponent } from './q/q-search.component';
import { InsightsBuilderComponent } from './insights-builder/insights-builder.component';
import { ManagementComponent } from './management/management.component';
import { ActivityComponent } from './insights/activity/activity.component';
import { NotFoundComponent } from './notfound.component';
import { MctradeManagementComponent } from './products/mctrade/mctrade-management/mctrade-management.component';
import {QDashboardMctradeComponent} from './products/mctrade/ai-assistant/q-dashboard-mctrade.component';
import {MctradeRevenueAssistantComponent} from './products/mctrade/ai-assistant/mctrade-revenue-assistant/mctrade-revenue-assistant.component';
import {MctradeCustomAssistantComponent} from './products/mctrade/ai-assistant/mctrade-custom-assistant/mctrade-custom-assistant.component';
import {MctradeEventsignupAssistantComponent} from './products/mctrade/ai-assistant/mctrade-eventsignup-assistant/mctrade-eventsignup-assistant.component';
import {MctradePaymentAssistantComponent} from './products/mctrade/ai-assistant/mctrade-payment-assistant/mctrade-payment-assistant.component';
import { MctradeProfileAssistantComponent} from './products/mctrade/ai-assistant/mctrade-profile-assistant/mctrade-profile-assistant.component';
import { MctradeDashboardHomeComponent} from './products/mctrade/dashboard/mctrade-dashboard-home/mctrade-dashboard-home.component';
import { MctradePredictiveAnalysisHomeComponent} from './products/mctrade/predictive-analysis/mctrade-predictive-analysis-home/mctrade-predictive-analysis-home.component';
import { MctradeSettingsComponent } from './products/mctrade/admin/mctrade-settings/mctrade-settings.component';
import { AiRequestComponent } from './insights/ai/ai-request/ai-request.component';
import { AdminTenantDatasetComponent } from './admin/admin-tenant-dataset/admin-tenant-dataset.component';
import { AdminTenantEditComponent } from './admin/admin-tenant-edit/admin-tenant-edit.component';
import { AdminApiInvokeComponent } from './admin/admin-api-invoke/admin-api-invoke.component';
import {LoginPsfyComponent} from './auth/login-psfy/login-psfy.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'post-login', component: PostLoginComponent },
  {path : 'login-psfy', component: LoginPsfyComponent},
  { path: 'admin', component: AdminComponent },
  { path: 'admin/tenant/:id', component: AdminTenantEditComponent },
  { path: 'admin/invoke', component: AdminApiInvokeComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'mctrade/membership-dashboard', component: MctradeMembershipDashboardComponent },
  { path: 'mctrade/revenue-dashboard', component: MctradeRevenueDashboardComponent },
  { path: 'mctrade/event-dashboard', component: MctradeEventDashboardComponent },
  { path: 'mctrade/my-dashboard', component: MctradeMyDashboardComponent },
  { path: 'insights-builder', component: InsightsBuilderComponent },
  { path: 'management', component: ManagementComponent },
  { path: 'activity', component: ActivityComponent },
  { path: 'mctrade/management', component: MctradeManagementComponent }, 
  { path: 'mctrade/ai-data-analyst/profile', component: MctradeProfileAssistantComponent },   
  { path: 'mctrade/ai-data-analyst/revenue', component: MctradeRevenueAssistantComponent },
  { path: 'mctrade/ai-data-analyst/payment', component: MctradePaymentAssistantComponent },
  { path: 'mctrade/ai-data-analyst/eventsignup', component: MctradeEventsignupAssistantComponent },
  { path: 'mctrade/ai-data-analyst/custom', component: MctradeCustomAssistantComponent },
  { path: 'mctrade/ai-data-analyst', component: QDashboardMctradeComponent },
  { path: 'mctrade/dashboard', component: MctradeDashboardHomeComponent },
  { path: 'mctrade/predictive-analysis', component: MctradePredictiveAnalysisHomeComponent },
  { path: 'mctrade/settings', component: MctradeSettingsComponent },
  { path: 'ai-assistant', component: AiRequestComponent },  
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
