import { UtilService } from './../../shared/util/util.service';
import { IdentityProviderService } from './../shared/identity-provider.service';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../shared/navigation/navigation.service';
import { AuthService } from '../shared/auth.service';


@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.less'],
})
export class PostLoginComponent implements OnInit {
  displayResponse: string;

  constructor(
    private _identityProviderService: IdentityProviderService,
    private _authService: AuthService,
    private _navigationService: NavigationService,
    private _utilService: UtilService
  ) {

    
  }

  async ngOnInit() {

    //https://localhost:4200/post-login?code=988001BBA9926C22DF2286C0C31616E95270B392406863BEC64A08F8047478A2&scope=openid%20profile%20psfy_products%20mc_trade_api&state=9ca839911522819178dfb3a1370a7a589d391fa1e59ad62ddf87fa4c&session_state=fkHNNxDAJAHn7V0K9RZ766Ej1Lf6zhgLD-dwFHeeDcg.613CE317254B8A478B8524A9EC1C7E6C&iss=https:%2F%2Fdev.psfyidp.com
    var referrer = document.referrer;
    if(referrer.includes("cognito")){
      this._identityProviderService.usePsfyIdp(false, "");
    }else{
      this._identityProviderService.usePsfyIdp(true, "");
    }
    
    this._identityProviderService.initialize();

    if (this._identityProviderService.hasAuthorizationCodeInUrl() === true) {
      let code = this._identityProviderService.extractAuthorizationCodeFromUrl();
      this._identityProviderService.authorizationCodeFlowExchangeCodeForAccessToken(code).then(
        async (data: any) => {
          this.displayResponse = JSON.stringify(data);
          if (data.access_token) {
            var isAdmin = false;
            var userSession = this._authService.getUserSession();

            userSession.accessToken = data.access_token;

            let decodedToken: any = this._authService.decodeToken(data.access_token);
            //let tenantsArray: Array<string> = decodedToken.psfy_tenants;
            userSession.userName = decodedToken.username;
            userSession.userId = decodedToken.sub;
            userSession.accessToken = data.access_token;

            userSession.powerUser = false;
            userSession.adminUser = false;

            var tenantId = '';
            var productId = '';

            if (this._utilService.isNotNullOrUndefined(decodedToken.psfy_products)) {
              if (Array.isArray(decodedToken.psfy_products) == false) {

                if(this._utilService.contains(decodedToken.psfy_products, "|") == true){
                  productId = decodedToken.psfy_products.split("|")[1];
                }else{
                  productId = decodedToken.psfy_products;
                }
                
              }
              else {
                for (let product of decodedToken.psfy_products) {
                  if (product.toLowerCase().includes('mc_trade') == true) {
                    productId = product;
                    break; //use first found
                  }
                }
              }
            }
            
            if (this._utilService.isNotNullOrUndefined(decodedToken.psfy_tenants)) {
              if (Array.isArray(decodedToken.psfy_tenants) == false) {
                tenantId = decodedToken.psfy_tenants.split("|")[1];
              }
              else {
                for (let tenant of decodedToken.psfy_tenants) {
                  if (tenant.toLowerCase().includes('mc_trade') == true) {
                    productId = 'mc_trade';
                    tenantId = tenant.split("|")[1];
                    break; //use first found
                  }
                }
              }
            }
            userSession.productId = productId;
            userSession.tenantId = tenantId;





            if (data.id_token) {
              let decodedIdToken: any = this._authService.decodeToken(data.id_token);
              userSession.userId = decodedIdToken["sub"];
              if (decodedIdToken["cognito:username"]) {
                //this came from the cognito login flow and is for a psfy admin
                userSession.userName = decodedIdToken["cognito:username"];
                userSession.adminUser = true;
                isAdmin = true;
              }
            }



            if (isAdmin) {
              this._authService.setAdminUserSession(userSession);
              this._navigationService.navigateToAdmin();
            } else {
              this._authService.setUserSession(userSession);
              this._navigationService.navigateToDashboard();
            }

          }


          // clear out PKCE storage values since we can't re-use them after a successful exchange
          this._identityProviderService.clearPkceLocalStorage();

        },
        (err: any) => {
          this.displayResponse = JSON.stringify(err.message);
        }
      );
    }
  }
}
