import { Component, OnInit } from '@angular/core';
import { AppConfig } from './app.config';
import { NavigationService } from './shared/navigation/navigation.service';
import { UtilService } from './shared/util/util.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { LogService } from './shared/log/log.service';
import { AuthService } from './auth/shared/auth.service';
import { UserSession } from './auth/shared/user-session.model';
import { NotificationService } from './shared/notification/notification.service';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { InisghtsService } from './insights/shared/insights.service';
import { SessionDto } from './insights/shared/dto/session-dto';
import { McTradeService } from './products/mctrade/shared/mctrade.service';
import { AssociationInfoDto } from './products/mctrade/shared/dto/association-info-dto';
import { MctradeTenantSettingsDto } from './products/mctrade/shared/dto/mctrade-tenant-settings-dto';
import { ActivitySearchDto } from './insights/shared/dto/activity-search-dto';
import { ActivityDto } from './insights/shared/dto/activity-dto';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public notificationMessage: string = "";
  public leftPanelMenuItems: MenuItem[];
  public routerIsLoading: boolean = false;
  public showNotificationSpinner: boolean = false;

  private _isSubscribedToRouter = false;
  public currentUserSession: UserSession = new UserSession
  public displayTenantName: string = "";
  public AsOfDate: Date;

  public NavDashboardSelected: boolean = true;
  public NavAiAssistantSelected: boolean = false;
  public NavPredictiveAnalysisSelected: boolean = false;
  public NavAiDataAnalystSelected: boolean = false;
  public NavSettingsSelected: boolean = false;
  public NavInsightsBuilderSelected: boolean = false;
  public enableInsightsBuilder: boolean = false;

  public hideNavigation: boolean = false;
  public isLoggedIn: boolean = true;

  constructor(private router: Router, private primengConfig: PrimeNGConfig, private _navigationService: NavigationService, private _utilService: UtilService, private _logService: LogService, private _authService: AuthService, private _notificationService: NotificationService, private _insightService: InisghtsService, private _mctradeService: McTradeService) {


    this._logService.info("Current locale: " + $localize.locale);

    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        identityPoolId: "us-east-1:e5a89489-24b4-4054-ae9c-d0d9568e9866",
        endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
        telemetries: ["errors", "performance", "http"],
        allowCookies: true,
        enableXRay: true,
        enableRumClient: true
      };

      const APPLICATION_ID: string = 'f42b7c47-8a1a-4c43-93d1-b2e379389465';
      const APPLICATION_VERSION: string = '1.0.0';
      const APPLICATION_REGION: string = 'us-east-1';

      const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Show a loading indicator or display a message        
        this.routerIsLoading = true;

        this._notificationService.info("navigating to " + event.url + "...", 2000, true);


      }
      else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {


        //update the on/off for the left nav
        if (this._utilService.containsAnyCase(event.url, "/ai-data-analyst")) {
          this.NavAiAssistantSelected = false;
          this.NavDashboardSelected = false;
          this.NavPredictiveAnalysisSelected = false;
          this.NavAiDataAnalystSelected = true;
        } else if (this._utilService.containsAnyCase(event.url, "/dashboard")) {
          this.NavAiAssistantSelected = false;
          this.NavDashboardSelected = true;
          this.NavPredictiveAnalysisSelected = false;
          this.NavAiDataAnalystSelected = false;
        } else if (this._utilService.containsAnyCase(event.url, "/predictive-analysis")) {
          this.NavAiAssistantSelected = false;
          this.NavDashboardSelected = false;
          this.NavPredictiveAnalysisSelected = true;
          this.NavAiDataAnalystSelected = false;
        } else if (this._utilService.containsAnyCase(event.url, "/ai-assistant")) {
          this.NavAiAssistantSelected = true;
          this.NavDashboardSelected = false;
          this.NavPredictiveAnalysisSelected = false;
          this.NavAiDataAnalystSelected = false;
        }

        if (this._utilService.containsAnyCase(event.url, "/admin")) {
          this.hideNavigation = true;
        } else {
          this.hideNavigation = false;

        }
        
        setTimeout(() => {
          this.routerIsLoading = false;


        }, 2000)



      }

    });

  }



  ngOnInit() {
    this.primengConfig.ripple = true;

    this.currentUserSession = this._authService.getUserSession();

    // if ( this.currentUserSession.powerUser == true) {
    //   this.enableInsightsBuilder = true;
    // }
    var routePath = window.location.pathname;

    if(routePath.startsWith("/unauthorized")){
      this.isLoggedIn = false;
    }
    if (routePath.startsWith("/admin") || routePath.startsWith("/Admin") || routePath.startsWith("/post-login") || routePath.startsWith("/Post-login") || routePath.startsWith("/Post-Login") || routePath.startsWith("/login") ) {
      //ignore auth redirect 
      var foo = "";
    }
    else if (this._utilService.hasQueryStringParam('sessionid') == true) {
      var sessionId = this._utilService.getQueryStringParamValue('sessionid', 'string')

      this.currentUserSession = new UserSession();
      this._authService.logout();

      this._insightService.GetValidSession(sessionId).then((data: SessionDto) => {

        this.currentUserSession.productId = data.productId;
        this.currentUserSession.tenantId = data.tenantId;
        this.currentUserSession.powerUser = data.powerUser;
        this.currentUserSession.adminUser = data.powerUser;
        this.currentUserSession.accessToken = data.token;
        this.currentUserSession.userId = data.sourceUserId;
        this.currentUserSession.userName = data.sourceUsername;

        this._authService.setUserSession(this.currentUserSession);

        //redirect to this page without the sessionid
        // var urlParts = this._utilService.getUrlParts(window.location.href);
        // var redirectUrl = "https://" + urlParts.host; //+ urlParts.pathname;
        // window.location.href = redirectUrl;
        this._navigationService.navigateToAppRootAndReload();

      }, () => {
        //session is not valid 
        this._logService.log('Session not Valid', 'app component has session id but its not valid.  Navigating to unauthorized.');
        this._navigationService.navigateToUnauthorized();
        
      });

    }
    else {

      this.currentUserSession = this._authService.getUserSession();

      this._authService.storedAccessTokenIsValid().then((data: boolean) => {

        if (data == false) {
          //send to unauthenticated
          this._logService.log('Session not Valid', 'app component does not have a session id and the stored token is invalid.  Navigating to unauthorized.');
          this._navigationService.navigateToUnauthorized();
        } else {

          if (this.currentUserSession.powerUser == true) {
            //add insights builder to nav
          }

          if (this.currentUserSession.adminUser == true) {
            //add admin to nav
          }

          //initialize pendo
          (window as any).pendo.initialize({
            visitor: {
              id: this.currentUserSession.userId,

            },
            account: {
              id: this.currentUserSession.tenantId,
              name: this.currentUserSession.displayName
            }
          });

          if (this.currentUserSession.productId == "mc_trade") {

            this._mctradeService.getAssociationInfoAssociationInfoDto(this.currentUserSession.tenantId).then((data: AssociationInfoDto) => {

              if (this._utilService.isNotNullEmptyOrWhitespace(data.orgName)) {
                this.currentUserSession.displayName = data.orgName;
              } else {
                this.currentUserSession.displayName = this.currentUserSession.tenantId;
              }

              this._authService.setUserSession(this.currentUserSession);

            }, () => {

              this.currentUserSession.displayName = this.currentUserSession.tenantId;
              this._authService.setUserSession(this.currentUserSession);

            });
          }


          this._insightService.GetLastSuccessfullDataIngestionActivities(this.currentUserSession.tenantId, 10).then((data: Array<ActivityDto>) => {

            this.AsOfDate = data[0].applyDate;
          })

        }


      });





    }




    // if (this._isSubscribedToRouter == false) {
    //   this._notificationService.notification.subscribe((notification) => {
    //     this._isSubscribedToRouter = true;
    //     if (notification != null) {
    //       this.notificationMessage = notification.message;
    //       setTimeout(() => {
    //         this.notificationMessage = "";
    //       }, notification.duration)
    //     }
    //   });
    // }


    this._notificationService.notification.subscribe((notification) => {

      if (notification != null) {
        this.notificationMessage = notification.message;
        if (notification.showSpinner == true) {
          this.showNotificationSpinner = true;
        }
        this.notificationMessage = notification.message;
        setTimeout(() => {
          this.notificationMessage = "";
          this.showNotificationSpinner = false;
        }, notification.duration)

      }

    });



  }



  login() {
    //this._navigationService.navigateToLogin();
    this._navigationService.navigateToPsfyLogin();
  }
  logout() {
    this._authService.logout();
    this._navigationService.navigateToLogout();
  }

  navigateToAdmin() {
    this._navigationService.navigateToAdmin();
  }
  public navigateToHome = (): Promise<boolean> => {
    return this._navigationService.navigateToHome();
  };

  public navSelectAiAssistant = (): Promise<boolean> => {
    this.NavAiAssistantSelected = true;
    this.NavDashboardSelected = false;
    this.NavPredictiveAnalysisSelected = false;
    this.NavAiDataAnalystSelected = false;
    this.NavSettingsSelected = false;
    this.NavInsightsBuilderSelected = false;
    return this._navigationService.navigateToAiAssistant();
  };

  public navSelectDashboard = (): Promise<boolean> => {
    this.NavAiAssistantSelected = false;
    this.NavDashboardSelected = true;
    this.NavPredictiveAnalysisSelected = false;
    this.NavAiDataAnalystSelected = false;
    this.NavSettingsSelected = false;
    this.NavInsightsBuilderSelected = false;
    return this._navigationService.navigateToDashboard();
  };



  public navSelectPredictiveAnalysis = (): Promise<boolean> => {
    this.NavAiAssistantSelected = false;
    this.NavDashboardSelected = false;
    this.NavPredictiveAnalysisSelected = true;
    this.NavAiDataAnalystSelected = false;
    this.NavSettingsSelected = false;
    this.NavInsightsBuilderSelected = false;
    return this._navigationService.navigateToPredictiveAnalysis();
  };

  public navSelectAiDataAnalyst = (): Promise<boolean> => {
    this.NavAiAssistantSelected = false;
    this.NavDashboardSelected = false;
    this.NavPredictiveAnalysisSelected = false;
    this.NavAiDataAnalystSelected = true;
    this.NavSettingsSelected = false;
    this.NavInsightsBuilderSelected = false;
    return this._navigationService.navigateToAiDataAnalyst();
  };

  public navSelectSettings() {

    this.NavAiAssistantSelected = false;
    this.NavDashboardSelected = false;
    this.NavPredictiveAnalysisSelected = false;
    this.NavAiDataAnalystSelected = false;
    this.NavSettingsSelected = true;
    this.NavInsightsBuilderSelected = false;
    return this._navigationService.navigateToSettings();

  }

  public navSelectInsightsBuilder() {

    this.NavAiAssistantSelected = false;
    this.NavDashboardSelected = false;
    this.NavPredictiveAnalysisSelected = false;
    this.NavAiDataAnalystSelected = false;
    this.NavSettingsSelected = false;
    this.NavInsightsBuilderSelected = true;
    return this._navigationService.navigateToInsightsBuilder();
  }


}
