import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/api/api.service';
import { RabTenantDto } from './dto/rab-tenant-dto';
import { CreateTenantConfigDto } from './dto/create-new-rab-tenant-dto';
import { SearchResultDto } from '../../shared/dto/search-result-dto';
import { CreateTenantSessionDto } from './dto/create-tenant-session-dto';
import { TenantDatasetDto } from './dto/tenant-dataset-dto';
import { UtilService } from 'src/app/shared/util/util.service';
import { Subject } from 'rxjs';
import { ValidationResultDto } from 'src/app/shared/dto/validation-result-dto';
import { IngestTenantDataDto } from './dto/ingest-tenant-data-dto';
import { TenantConfigDto } from './dto/tenant-config-dto';


@Injectable({
  providedIn: 'root'
})
export class AdminService {


  private selectedTenantSubject = new Subject<RabTenantDto>()
  private selectedTenantConfigSubject = new Subject<TenantConfigDto>()
  public selectedTenant = this.selectedTenantSubject.asObservable()
  public  selectedTenatConfig = this.selectedTenantConfigSubject.asObservable()

  public constructor(private _apiService: ApiService, private _utilService: UtilService) {


  }



  public tenantExists = (product: string, tenant: string): Promise<boolean> => {

    return this._apiService.getNoCache('api/v1/insights/control-plane/' + product + '/' + tenant + '/exists');

  }

  public getTenants = (): Promise<SearchResultDto<RabTenantDto>> => {

    return this._apiService.getNoCache('api/v1/insights/common/tenants?pagesize=9999');

  }

  public getTenant = ( tenantId: string): Promise<RabTenantDto> => {

    return this._apiService.getNoCache('api/v1/insights/common/tenant/' + tenantId);

  }

  public selectTenant(tenant: RabTenantDto) {
    this.selectedTenantSubject.next(tenant)
  }
  public selectTenantConfig(tenantConfig: TenantConfigDto) {
    this.selectedTenantConfigSubject.next(tenantConfig)
  }


  public createNewMcTradeTenant = (tenant: CreateTenantConfigDto): Promise<void> => {
    //will create the record and all resources
    let url: string = 'api/v1/insights/mctrade/' + tenant.tenantId;
    tenant.injestionDataOverwriteExisting = true;
    tenant.injestionDataStartDate = this._utilService.addDays(new Date(), -20);
    tenant.injestionDataEndDate = new Date();
    tenant.ingestDataFirst = true;
    
    return this._apiService.post(url, tenant);

  }

  public updateTenant = (tenant: RabTenantDto): Promise<RabTenantDto> => {

    let url: string = 'api/v1/insights/common/tenant/' + tenant.tenantId;
    return this._apiService.put(url, tenant);

  }

  public deleteTenant = (tenant: RabTenantDto): Promise<void> => {

    let url: string = 'api/v1/insights/mctrade/' + tenant.tenantId;
    return this._apiService.delete(url, tenant);

  }

  public getTenantSessionId = (productId: string, tenantId: string): Promise<string> => {

    let url: string = 'api/v1/insights/common/session';
    var payload = new CreateTenantSessionDto();
    payload.tenantId = tenantId;
    payload.productId = productId;
    payload.adminUser = true;
    payload.powerUser = true;
    payload.sourceUsername = "TempUser"
    payload.sourceUserId = "TempUserId"
    return this._apiService.post(url, payload);

  }

  public getDatasets = (productId: string, tenantId: string): Promise<Array<TenantDatasetDto>> => {

    return this._apiService.getNoCache('api/v1/insights/common/' + productId + '/' + tenantId + '/datasets');

  }
  public validateDataset = (dataset: TenantDatasetDto): Promise<ValidationResultDto> => {
       
      return this._apiService.post('api/v1/insights/mctrade/' + dataset.tenantId + '/datasets/custom/validate', dataset);
   
  }

  public upsertDataset = (dataset: TenantDatasetDto): Promise<string> => {

     
    return this._apiService.put('api/v1/insights/mctrade/' + dataset.tenantId + '/datasets/custom', dataset);


  }

  public deleteDataset = (dataset: TenantDatasetDto): Promise<string> => {

    //api/v1/insights/mctrade/{tenantId}/datasets/custom
    return this._apiService.delete('api/v1/insights/mctrade/' + dataset.tenantId + '/datasets/custom/' + dataset.id, null);


  }

  public GenerateMCTradeMLModels = (tenantId: string): Promise<void> => {
    return this._apiService.postNoPayload("api/v1/insights/mctrade/" + tenantId + "/models/generate");
  }

  public  enableOrDisableDataInsightsMenuItemInMcTrade = (tenantId: string,  enable : boolean): Promise<void> => {

    if(enable == true){
      return this._apiService.postNoPayload("api/v1/insights/mctrade/" + tenantId + "/menuitem/enable");
    }else{
      return this._apiService.postNoPayload("api/v1/insights/mctrade/" + tenantId + "/menuitem/disable");
    }
    
  }

  public  GetDataInsightsMenuItemInMcTradeValue = (tenantId: string): Promise<boolean> => {

     
      return this._apiService.getNoCache("api/v1/insights/mctrade/" + tenantId + "/menuitem/value");
    
    
  }

  public IngestMcTradeData = (ingestionConfig : IngestTenantDataDto): void => {

    var url = "api/v1/insights/mctrade/" + ingestionConfig.tenantId + "/ingestion/ingest";
    this._apiService.post(url, ingestionConfig)


  }

  public getTenantConfig = (productId: string, tenantId: string): Promise<TenantConfigDto> => {
    var config = new TenantConfigDto();

    return this._apiService.getNoCache("api/v1/insights/common/" + productId +"/" + tenantId + "/settings/config");
  }

  public upsertTenantConfig = (productId: string, tenantId: string, config : TenantConfigDto): Promise<void> => {
        return this._apiService.put("api/v1/insights/common/" + productId +"/" + tenantId + "/settings/config", config);
  }
}
