import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';


// interceptor imports
import { AuthInterceptor } from './shared/http/interceptors/auth-interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { StyleClassModule } from 'primeng/styleclass';
import { MenuModule } from 'primeng/menu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { PanelModule } from 'primeng/panel'
import { DialogModule } from 'primeng/dialog'
import { TabViewModule } from "primeng/tabview";
import { DataViewModule } from "primeng/dataview";
import { AccordionModule } from 'primeng/accordion'
import { MegaMenuModule } from 'primeng/megamenu'
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CardModule } from 'primeng/card';

import { NgxVisibilityModule, NgxVisibilityDirective } from 'ngx-visibility';

import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { UnauthorizedComponent } from './auth/unauthorized.component';
import {LoginComponent} from './auth/login/login.component';
import {LoginPsfyComponent} from './auth/login-psfy/login-psfy.component';
import { LogoutComponent } from './auth/logout/logout.component';


import { MctradeMembershipDashboardComponent } from './products/mctrade/dashboard/mctrade-membership-dashboard/mctrade-membership-dashboard.component';
import { MctradeEventDashboardComponent } from './products/mctrade/dashboard/mctrade-event-dashboard/mctrade-event-dashboard.component';
import { MctradeRevenueDashboardComponent } from './products/mctrade/dashboard/mctrade-revenue-dashboard/mctrade-revenue-dashboard.component';
import { MctradeMyDashboardComponent } from './products/mctrade/dashboard/mctrade-my-dashboard/mctrade-my-dashboard.component';
import { QSearchComponent } from './q/q-search.component';
import { InsightsBuilderComponent } from './insights-builder/insights-builder.component';
import { ManagementComponent } from './management/management.component';
import { DataManagementComponent } from './management/data/data-management.component';
import { MctradeMembershipPredictiveMlComponent } from './products/mctrade/predictive-analysis/mctrade-membership-predictive-ml/mctrade-membership-predictive-ml.component';
import { MctradeRevenuePredictiveMlComponent } from './products/mctrade/predictive-analysis/mctrade-revenue-predictive-ml/mctrade-revenue-predictive-ml.component';
import { ActivityComponent } from './insights/activity/activity.component';
import {NotFoundComponent} from './notfound.component';
import { MctradeManagementComponent } from './products/mctrade/mctrade-management/mctrade-management.component';
import {MembershipSegmentationMlComponent} from  './products/mctrade/predictive-analysis/mctrade-membership-segmentation-ml/mctrade-membership-segmentation-ml.component';
import {QDashboardMctradeComponent} from './products/mctrade/ai-assistant/q-dashboard-mctrade.component';
import {MctradeRevenueAssistantComponent} from './products/mctrade/ai-assistant/mctrade-revenue-assistant/mctrade-revenue-assistant.component';
import {MctradeCustomAssistantComponent} from './products/mctrade/ai-assistant/mctrade-custom-assistant/mctrade-custom-assistant.component';
import {MctradeEventsignupAssistantComponent} from './products/mctrade/ai-assistant/mctrade-eventsignup-assistant/mctrade-eventsignup-assistant.component';
import {MctradePaymentAssistantComponent} from './products/mctrade/ai-assistant/mctrade-payment-assistant/mctrade-payment-assistant.component';
import { MctradeProfileAssistantComponent} from './products/mctrade/ai-assistant/mctrade-profile-assistant/mctrade-profile-assistant.component';
import { MctradeDashboardHomeComponent } from './products/mctrade/dashboard/mctrade-dashboard-home/mctrade-dashboard-home.component';
import { MctradePredictiveAnalysisHomeComponent } from './products/mctrade/predictive-analysis/mctrade-predictive-analysis-home/mctrade-predictive-analysis-home.component';
import { MctradeSettingsComponent } from './products/mctrade/admin/mctrade-settings/mctrade-settings.component';
import { AiRequestComponent } from './insights/ai/ai-request/ai-request.component';
import { AdminTenantDatasetComponent } from './admin/admin-tenant-dataset/admin-tenant-dataset.component';
import { AdminTenantEditComponent } from './admin/admin-tenant-edit/admin-tenant-edit.component';
import { AdminAuthComponent } from './admin/admin-auth/admin-auth.component';
import { AdminApiInvokeComponent } from './admin/admin-api-invoke/admin-api-invoke.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UnauthorizedComponent,
    LoginComponent,
    LogoutComponent,
    MctradeMembershipDashboardComponent,
    MctradeEventDashboardComponent,
    MctradeRevenueDashboardComponent,
    MctradeMyDashboardComponent,
    QSearchComponent,
    InsightsBuilderComponent, 
    AdminComponent,
    ManagementComponent,
    DataManagementComponent,
    MctradeMembershipPredictiveMlComponent,
    MctradeRevenuePredictiveMlComponent,
    ActivityComponent,
    NotFoundComponent,
    MctradeManagementComponent,
    MembershipSegmentationMlComponent,
    QDashboardMctradeComponent,
    MctradeRevenueAssistantComponent,
    MctradeCustomAssistantComponent,
    MctradeEventsignupAssistantComponent,
    MctradePaymentAssistantComponent,
    MctradeProfileAssistantComponent,
    MctradeDashboardHomeComponent,
    MctradePredictiveAnalysisHomeComponent,
    MctradeSettingsComponent,
    AiRequestComponent,
    AdminTenantDatasetComponent,
    AdminTenantEditComponent,
    AdminAuthComponent,
    AdminApiInvokeComponent,
    LoginPsfyComponent
  ],
  imports: [
    RouterModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogModule,
    PanelModule,
    InputTextareaModule,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    FileUploadModule,
    CheckboxModule,
    TableModule,
    ChartModule,
    AppRoutingModule,
    CommonModule,
    InputTextModule,
    BadgeModule,
    StyleClassModule,
    ProgressSpinnerModule,
    MenuModule,
    PanelMenuModule,
    ButtonModule,
    RippleModule,
    FormsModule,
    RadioButtonModule,
    InputSwitchModule,
    SidebarModule,
    TabViewModule,
    DataViewModule,
    AccordionModule,
    MegaMenuModule,
    ProgressBarModule,
    ToastModule,
    MessageModule,
    OverlayPanelModule,
    CardModule,
    NgxVisibilityModule
  ],
  providers: [      
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
